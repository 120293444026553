var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-img-container",class:{
    'loading--electro': _vm.isLoading && _vm.getTheme() === 'ecumene',
    'loading--ecumene': _vm.isLoading && _vm.getTheme() === 'electro'
  }},[_c('img',{ref:"img",staticClass:"profile-img",class:{
    loaded: !_vm.isLoading,
       flipedImage: this.flipedImage
  },attrs:{"loading":"lazy","src":_vm.src,"alt":"profile"},on:{"load":_vm.onLoad}})])
}
var staticRenderFns = []

export { render, staticRenderFns }